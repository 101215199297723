import React from 'react';

import NavigatioItems from './navigationItems';

import reglamento from '../../download/REGLAMENTO_LA_HACIENDA_JUN21.pdf';

export default function NavMenu({ switchMenuHandler, isOpen }) {
  return (
    <div
      className="absolute top-0 left-0 h-screen w-full flex flex-col justify-center"
      hidden={!isOpen}>
      <p className="uppercase text-white text-sm tracking-widest m-0 ml-20 mt-6 md:text-center md:ml-0">
        real estate
      </p>
      <nav className="flex-grow">
        <NavigatioItems closeMenu={switchMenuHandler} />
      </nav>
      <div className="flex mx-auto mb-14 text-xl">
        <a
          href="mailto:info@northouses.com"
          className="text-white mr-3 block hover:underline">
          email
        </a>
        <a
          href="https://www.instagram.com/northouses.fsa/"
          className="text-white mr-3 block hover:underline">
          instagram
        </a>
        <a
          href="https://www.facebook.com/northouses"
          className="text-white mr-3 block hover:underline">
          facebook
        </a>
        <a
          href="https://wa.me/+5493704916985"
          className="text-white mr-3 block hover:underline">
          whatsapp
        </a>
        <a
          download
          href={reglamento}
          className="text-white block hover:underline">
          reglamento
        </a>
      </div>
    </div>
  );
}
