import React from 'react';

export default function Contacto() {
  return (
    <div className="w-11/12 mx-auto flex-grow flex flex-col justify-between bg-black relative mt-20 md:mt-32 p-6 rounded-t-md">
      <h1 className="text-white m-0 absolute top-0 transform -translate-y-2/3 self-center text-6xl md:text-[10rem] tracking-wider">
        Contacto
      </h1>
      <div className="mt-6 md:mt-20 xl:flex justify-around">
        <div className="max-w-[480px]">
          <h3 className="text-white text-3xl mb-6">
            Escribinos un correo con cualquier consulta que tengas o para
            solicitar más información.
          </h3>
        </div>
        <div className="md:flex">
          <div className="mr-6 mb-6">
            <h4 className="text-green-600 uppercase text-sm mb-2">dirección</h4>
            <p className="text-white m-0">
              Av Gutniski 4535
              <br />
              3600 Formosa
              <br />
              Provincia de Formosa, Argentina
            </p>
          </div>
          <div className="mr-6 mb-6">
            <h4 className="text-green-600 uppercase text-sm mb-2">email</h4>
            <a
              href="mailto:info@northouses.com"
              className="text-white hover:underline">
              info@northouses.com
            </a>
          </div>
          <div>
            <h4 className="text-green-600 uppercase text-sm mb-2">redes</h4>
            <a
              href="https://wa.me/+5493704916985"
              target="_blank"
              rel="noreferrer"
              className="text-white block hover:underline">
              whatsapp
            </a>
            <a
              href="https://www.facebook.com/northouses"
              target="_blank"
              rel="noreferrer"
              className="text-white block hover:underline">
              facebook
            </a>
            <a
              href="https://www.instagram.com/northouses.fsa/"
              target="_blank"
              rel="noreferrer"
              className="text-white block hover:underline">
              instagram
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
