import React from 'react';

export default function Backdrop({ show }) {
  return (
    <div
      className={`absolute z-10 top-0 left-0 w-full h-screen bg-green-900 transition transform ${
        show ? 'translate-x-0' : 'translate-x-full'
      }`}></div>
  );
}
