import React from 'react';
import { Link } from 'gatsby';

export default function NavigatioItems({ closeMenu }) {
  return (
    <ul className="m-0 w-full h-full mx-auto flex flex-col justify-center items-stretch text-white text-4xl md:text-6xl">
      <li className="mx-0 my-6">
        <Link
          to="/"
          className="p-2 w-full block border-b-2 border-white group"
          onClick={closeMenu}>
          <p className="transition transform group-hover:translate-x-6">
            Inicio
          </p>
        </Link>
      </li>
      <li className="mx-0 my-6">
        <Link
          to="/#desarrollos"
          className="p-2 w-full block border-b-2 border-white group"
          onClick={closeMenu}>
          <p className="transition transform group-hover:translate-x-6">
            Desarrollos
          </p>
        </Link>
      </li>
      <li className="mx-0 my-6">
        <Link
          to="#contacto"
          className="p-2 w-full block border-b-2 border-white group"
          onClick={closeMenu}>
          <p className="transition transform group-hover:translate-x-6">
            Contacto
          </p>
        </Link>
      </li>
    </ul>
  );
}
