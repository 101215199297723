import React, { useState } from 'react';
import { useSpring, a } from 'react-spring';

import NavMenu from './navMenu';
import LogoLight from '../UI/logoLight';

export default function NavBar({ toggleBackdrop }) {
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Toggles the backdrop, then switches the menu state
   */
  const switchMenuHandler = () => {
    toggleBackdrop();
    setIsOpen(!isOpen);
  };

  /**
   * @param  {} event
   * TODO: manage key press event
   */
  const keyPressHandler = (event) => {
    console.log(event.key);
  };

  /**
   * Logo and menu button reveal
   */
  const fadeIn = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 400,
  });

  return (
    <a.div
      style={fadeIn}
      className="w-11/12 h-16 mx-auto flex justify-between items-center z-20 relative">
      <LogoLight />
      {/* Menu button */}
      <a.div
        className="h-12 w-32 border transition hover:bg-green-900 border-white flex items-center justify-center absolute right-0 my-auto z-50"
        role="button"
        tabIndex="0"
        onKeyPress={keyPressHandler}
        onClick={switchMenuHandler}>
        <p className="m-0 text-white uppercase tracking-wider">
          {isOpen ? 'cerrar' : 'menu'}
        </p>
      </a.div>
      <NavMenu switchMenuHandler={switchMenuHandler} isOpen={isOpen} />
    </a.div>
  );
}
