import React from 'react';

import LogoLight from '../UI/logoLight';
import Contacto from '../sections/contacto';

import reglamento from '../../download/REGLAMENTO_LA_HACIENDA_JUN21.pdf';

export default function Footer() {
  return (
    <footer className="bg-green-900 flex flex-col pt-6" id="contacto">
      {/* CONTACTO */}
      <Contacto />

      {/* MAPA */}
      <iframe
        title="Mapa de ubicación"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.964835039552!2d-58.21429378427564!3d-26.197822170077984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945caf4a80e06427%3A0xc26d30f13f7dd7d1!2sAv.%20Dr.%20Luis%20Gutniski%204535%2C%20P3600COB%20Formosa!5e0!3m2!1ses-419!2sar!4v1622616788031!5m2!1ses-419!2sar"
        allowFullScreen
        style={{ filter: 'grayscale(100%)' }}
        className="w-full h-96 m-0 border-none"
        loading="lazy"></iframe>

      {/* FOOTER */}
      <div className="w-11/12 mx-auto flex flex-col md:flex-row justify-between md:justify-center items-center py-4 md:p-2">
        <a href="#top" className="m-0">
          <LogoLight className="md:w-9  " />
        </a>
        <p className="text-white m-0 block my-2 mx-12">
          northouses © {new Date().getFullYear()}
        </p>
        <div className="flex">
          <a
            href="mailto:info@northouses.com"
            className="text-white mr-3 block hover:underline">
            email
          </a>
          <a
            href="https://www.instagram.com/northouses.fsa/"
            className="text-white mr-3 block hover:underline">
            instagram
          </a>
          <a
            href="https://www.facebook.com/northouses"
            className="text-white mr-3 block hover:underline">
            facebook
          </a>
          <a
            href="https://wa.me/+5493704916985"
            className="text-white mr-3 block hover:underline">
            whatsapp
          </a>
          <a
            download
            href={reglamento}
            className="text-white block hover:underline">
            reglamento
          </a>
        </div>
      </div>
    </footer>
  );
}
