/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import './layout.css';

import Header from './header';
import Footer from './footer';
import Backdrop from '../UI/backdrop';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const switchBackdrop = () => {
    setShowBackdrop(!showBackdrop);
  };

  return (
    <div className="relative overflow-x-hidden">
      <Backdrop show={showBackdrop} />
      <Header toggleBackdrop={switchBackdrop} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
