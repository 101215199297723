import * as React from 'react';

import NavBar from '../navigation/navBar';

const Header = ({ toggleBackdrop }) => (
  <header className="absolute left-0 top-0 w-full mt-6 z-50">
    <NavBar toggleBackdrop={toggleBackdrop} />
  </header>
);

export default Header;
