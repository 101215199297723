import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function LogoLight({ className }) {
  return (
    <StaticImage
      src="../../images/NH_logo_h-BL-short.png"
      alt="Northouses Logo"
      className={`w-16 ${className}`}
      loading="eager"
      placeholder="blurred"
    />
  );
}
